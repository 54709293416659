import * as React from "react";
import Layout from "../../../components/Layout";
import { faArrowCircleDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StaticImage } from "gatsby-plugin-image";
import ScopriAltriBonusECM from "../../../components/ScopriAltriBonusECM/ScopriAltriBonusECM";

export default function SperimentazioneMedicinaliPage() {
  return (
    <Layout pageTitle="Bonus ECM - Sperimentazione Medicinali">
      <section className="position-relative">
        <StaticImage
          src="../../../images/background-bonus-crediti.png"
          className="img-header-home"
          alt="Bonus ECM"
          title="Bonus ECM"
        />
        <div className="container-fluid px-0 py-5 container-absolute-450 d-flex flex-column justify-content-center">
          <div className="container py-5">
            <h1
              title="Bonus ECM - Professionisti sanitari che hanno prestato servizio presso
              strutture che si occupano di sperimentazione di medicinali"
              className="text-center text-white display-3"
            >
              Bonus ECM - Professionisti sanitari che hanno prestato servizio
              presso strutture che si occupano di sperimentazione di medicinali
            </h1>
            <p
              className="text-center text-white"
              style={{ fontSize: 22, fontWeight: 400 }}
            >
              Lavorare alla sperimentazione di medicinali ti permette di
              acquisire crediti ECM per la tua formazione professionale.
            </p>
            <a href="#sezione-corsi" className="btn btn-outline-warning">
              <FontAwesomeIcon icon={faArrowCircleDown} className="mr-2" />{" "}
              Scopri di più
            </a>
          </div>
        </div>
      </section>
      <div id="sezione-corsi" className="container-fluid p-5">
        <p>
          Il <b>22 giugno 2022</b> la Commissione Nazionale per la Formazione
          Continua ha deliberato il rilascio di crediti formativi per i{" "}
          <b>
            professionisti sanitari che operano in strutture sanitarie impegnate
            nella sperimentazione clinica dei medicinali
          </b>{" "}
          e seguono un aggiornamento periodico svolto tramite{" "}
          <b>
            percorsi assistenziali multidisciplinari o a percorsi formativi di
            partecipazione diretta
          </b>
          . Scarica la delibera,{" "}
          <a
            href="/Delibera_Studi_Ricerche_22_04_2022.pdf"
            target="_blank"
            rel="noopener noreferrer"
            title="Delibera CNFC sperimentazione medicinali"
          >
            clicca qui
          </a>
        </p>
        <p>
          Tale attività rientra nella categoria della{" "}
          <b>Formazione individuale</b>.
        </p>
        <p>
          Se l’aggiornamento periodico del personale è realizzato tramite
          percorsi assistenziali multidisciplinari che danno rilevanza a:
        </p>
        <ul>
          <li>medicina di genere e all'età pediatrica</li>
          <li>comunicazione tra il medico e il paziente</li>
          <li>aspetti etici e deontologici e multi professionali</li>
        </ul>
        <p>
          nonché tramite percorsi formativi di partecipazione diretta a
          programmi di ricerca clinica multicentrici, la normativa prevede il
          rilascio di:
        </p>
        <ul>
          <li>
            <b>8 crediti ECM</b> per sperimentazioni fino a 6 mesi
          </li>
          <li>
            <b>16 crediti ECM</b> per sperimentazioni da 6 a 12 mesi
          </li>
          <li>
            <b>32 crediti ECM</b> per sperimentazioni oltre i 12 mesi
          </li>
        </ul>
        <p>
          Le suddette modifiche saranno operative dal <b>01/01/2023</b> essendo
          subordinate all'implementazione dei sistemi informatici da parte delle
          Regioni.
        </p>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center">
            <h4
              title="Vuoi rimanere sempre aggiornato sulle novità Bonus ECM?"
              className="my-4 text-center titolo-sezione fw-bold"
              style={{ fontSize: 32 }}
            >
              Vuoi rimanere sempre aggiornato sulle novità Bonus ECM?
            </h4>
            <p className="text-center">
              I tuoi dati non verranno condivisi con nessun Provider.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid pb-5">
        <div className="row">
          <div className="col-12 text-center">
            <a
              href="http://d7g5i.emailsp.com/frontend/forms/Subscription.aspx?idList=28&idForm=285&guid=5ddc68f0-845c-4ca3-b4ef-d19c93033a5c"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-outline-primary btn-lg"
            >
              Iscriviti alla newsletter
            </a>
          </div>
        </div>
      </div>
      <ScopriAltriBonusECM
        dossierFormativo={true}
        emergenzaSanitaria={true}
        formazioneIndividuale={true}
      />
    </Layout>
  );
}
